<template>
  <div
    @click="toggle"
    class="logo d-flex flex-column align-items-center justify-content-center m-auto"
  >
    <svg v-if="isAlt" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.95 192.95"><path d="M83.48,193,67,154.87l36.22-83.7H30.79L17.16,39.67H149.79ZM153.32,31.51H13.63L0,0H167Z" :style="style"/></svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.95 187.04"><path d="M60.63,0H0C0,.13,0,.26,0,.38,0,17.57,13.57,31.51,30.32,31.51h123L167,0Z" :style="style"/><path d="M77.79,39.82H17.17c0,.13,0,.25,0,.38,0,17.18,13.58,31.12,30.32,31.12h55.74l-33,76.31,0,0A29.76,29.76,0,0,0,86.1,187h0l12.67-29.28h0l51-117.94h-72Z" :style="style"/></svg>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  computed: {
    style() {
      return `fill:${this.color}`;
    },
  },
  methods: {
    toggle() {
      this.isAlt = !this.isAlt;
    },
  },
  data() {
    return {
      color: '#fff',
      isAlt: false,
    };
  },
};
</script>

<style lang="scss" scoped>
$colorGreenGradientTop: #ade1cd;
$colorGreenGradientBottom: #6de4b5;

.logo {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: $colorGreenGradientBottom;
  background: linear-gradient(45deg, $colorGreenGradientBottom 0%, $colorGreenGradientTop 100%);

  svg {
    display: block;
    max-width: 50%;
    height: auto;
    margin-top: 1rem;
  }
}
</style>
